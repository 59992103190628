import { GroupCondition } from '@/api/enums';
import {
  contains,
  equal,
  greater,
  group,
  handleAxiosError,
  isIn,
  isNotIn,
  lower,
  nested,
  notEqual,
} from '@/api/helpers';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { Branch, BranchDescendant } from '@/client/branches';
import { CourseEntityEnrollEnum, MaterialType } from '@/client/courses';
import { Group } from '@/client/groups';
import {
  CoursePlanner,
  CourseSchedulePeriodUnit,
  PlannerCRUDCourse,
  PlannerTemplateType,
  PlannerType,
} from '@/client/planner/types';
import { Actions, Subjects, User } from '@/client/users';
import { LoadingStatuses } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useAccountUsersPartialRequest,
  useBranchesPartialRequest,
  useCoursesPartialRequest,
  useGroupsPartialRequest,
} from '@/hooks/query';
import {
  useCreatePlanner,
  useDeletePlanner,
  useUpdatePlanner,
} from '@/hooks/query/planner.hooks';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { EditableText } from '@/ui/editable-text';
import { FlexContainer } from '@/ui/styled-ui';
import { branchAdminCheck, RemoveIconPath } from '@/utils/helpers';
import { getAllParentIds } from '@/utils/helpers/branches.helper';
import {
  formatIncludeExcludeEntities,
  getEntityIdsByType,
  processPlanIncludeExcludeEntities,
} from '@/utils/planner';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { Dialog, DialogProps } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { PlannerCRUDTimeline } from '../PlannerCRUDTimeline';

const StyledDialog = styled(Dialog)`
  width: 100%;
  max-width: 1400px;
  max-height: 650px;
  height: 100%;

  .p-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
  }

  .p-dialog-header .p-dialog-header-icons {
    margin: auto;
    margin-right: 0;
  }
`;

const StyledGrayFlexContainer = styled(FlexContainer)`
  background-color: var(--beige-main);
  padding: var(--big-padding);
  padding-bottom: 0;
  margin-top: var(--default-padding);
  height: 100%;
`;

const StyledSpan = styled.span`
  font-size: var(--xsmall-font-size);
  color: var(--black-main);
`;

const StyledMultiSelect = styled(MultiSelect)`
  .p-multiselect-label {
    flex-wrap: nowrap;
  }
  .p-multiselect-token-label {
    &::first-letter {
      color: var(--gray-darker);
      margin-right: 4px;
    }
  }
`;

const StyledTopDiv = styled.div`
  width: 100%;
  color: var(--black-main);
  padding: var(--medium-padding) var(--default-padding) 0 var(--default-padding);
`;

const StyledBottomDiv = styled.div`
  width: 100%;
  margin-top: var(--default-padding);
  padding: var(--default-padding);
  padding-top: 0;
`;

const StyledLink = styled.div`
  cursor: pointer;
  color: var(--red-main);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);

  &:hover {
    color: var(--red-dark);
  }
`;

const StyledDeleteInfoSpan = styled.span`
  color: var(--gray-darker);
`;

const StyledHeaderContainer = styled(FlexContainer)`
  align-items: center;
  flex-wrap: wrap;

  h1 {
    max-width: 100%;
  }
`;

const StyledHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  p {
    font-size: 14px;
    font-weight: 500;
  }
`;

type CreateUpdatePlannerModalProps = {
  plan?: CoursePlanner;
  refetch?: () => void;
  plannerType: PlannerType | undefined;
  plannerTemplateType?: PlannerTemplateType;
} & DialogProps;

type GroupedEntitiesType = {
  label: string;
  items: Account[] | Branch[] | Group[];
}[];

export const CreateUpdatePlannerModal: React.FC<
  CreateUpdatePlannerModalProps
> = ({ plan, visible, onHide, refetch, plannerType, plannerTemplateType }) => {
  const { can } = usePermission();
  const { setDialogData } = useContext(DialogContext);
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const user = useAppSelector(selectCurrentUser);
  const isBranchAdmin = branchAdminCheck(user, account);
  const groupedEntitiesInclude = useRef<GroupedEntitiesType>([]);
  const groupedEntitiesExclude = useRef<GroupedEntitiesType>([]);
  const [planName, setPlanName] = useState<string>('');
  const [autoEnroll, setAutoEnroll] = useState(false);
  const [autoEnrollTemp, setAutoEnrollTemp] = useState(false);
  const [isAutoEnrollConfirmVisibile, setIsAutoEnrollConfirmVisibile] =
    useState(false);
  const [userBranches, setUserBranches] = useState<string[]>([]);

  const [includedEntities, setIncludedEntities] = useState<
    (Account | Branch | Group)[]
  >([]);
  const [excludedEntities, setExcludedEntities] = useState<(Branch | Group)[]>(
    [],
  );

  const [includedEntitiesDescendants, setIncludedEntitiesDescendants] =
    useState<(Branch | Group)[]>([]);
  const [excludedEntitiesDescendants, setExcludedEntitiesDescendants] =
    useState<(Branch | Group)[]>([]);

  const [selectedCourses, setSelectedCourses] = useState<PlannerCRUDCourse[]>(
    [],
  );

  const {
    branches,
    isLoading: isBranchesLoading,
    refetch: refetchBranches,
  } = useBranchesPartialRequest({
    accountId: account?.id,
    filters: [equal('active', true), isIn('id', userBranches)],
    sort: ['name,asc'],
    withDescendants: true,
    take: 20,
    enabled: !isBranchAdmin,
  });

  const { groups, isLoading: isGroupsLoading } = useGroupsPartialRequest({
    accountId: account?.id,
    sort: ['name,asc'],
    enabled: !isBranchAdmin,
  });

  useEffect(() => {
    if (isBranchAdmin) {
      const fetchBranchAdminBranches = async () => {
        const response = await client.branches.getBranches(
          {
            filters: [equal('active', true), equal('id', user?.branch?.id)],
            take: 1,
            withDescendants: true,
          },
          account?.id,
        );

        const branch = response.result[0];

        if (branch) {
          setUserBranches([
            branch.id,
            ...(branch?.descendants?.map(({ id }) => id) || []),
          ]);
        }

        setTimeout(() => {
          refetchBranches();
        });
      };

      fetchBranchAdminBranches();
    }
  }, [user]);

  const [shouldRefetchUsers, setShouldRefetchUsers] = useState(true);
  const [isExcludeUsers, setIsExcludeUsers] = useState(false);
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const groupedUsers = useRef<GroupedEntitiesType>([]);
  const { isLoading: isUsersLoading, refetch: refetchUsers } =
    useAccountUsersPartialRequest({
      accountId: account?.id,
      filters: [
        equal('active', true),
        group(GroupCondition.OR, [
          group(GroupCondition.AND, [
            nested('branch', [
              isIn(
                'id',
                getEntityIdsByType(
                  [...includedEntities, ...includedEntitiesDescendants],
                  CourseEntityEnrollEnum.BRANCHES,
                ),
              ),
            ]),
            nested('branch', [
              isNotIn(
                'id',
                getEntityIdsByType(
                  [...excludedEntities, ...excludedEntitiesDescendants],
                  CourseEntityEnrollEnum.BRANCHES,
                ),
              ),
            ]),
          ]),
          nested('groups', [
            isIn(
              'id',
              getEntityIdsByType(
                includedEntities,
                CourseEntityEnrollEnum.GROUPS,
              ),
            ),
          ]),
        ]),
      ],
      sort: ['name,asc'],
      enabled: false,
    });

  const today = useMemo(() => new Date(), []);

  const { courses: upcomingCourses, isLoading: areUpcomingCoursesLoading } =
    useCoursesPartialRequest({
      filters: [
        equal('deletedAt', null),
        equal('retired', false),
        equal('type', MaterialType.COURSE),
        notEqual('releaseDate', null),
        greater('releaseDate', today),
      ],
      enabled: visible && plannerType === PlannerType.TRAINING_PLAN,
    });

  const {
    courses: recommendedCourses,
    isLoading: areRecommendedCoursesLoading,
  } = useCoursesPartialRequest({
    filters: [
      equal('availableToAccounts', account?.id),
      equal('retired', false),
      group(GroupCondition.OR, [
        equal('type', MaterialType.COURSE),
        ...(account?.meta?.enableMaterialsUpload
          ? [equal('type', MaterialType.CUSTOM_MATERIAL)]
          : []),
      ]),
      contains('label', [plannerType]),
      ...(plannerType === PlannerType.ONBOARDING_PLAN
        ? [
            group(GroupCondition.AND, [
              group(GroupCondition.OR, [
                lower('releaseDate', today, true),
                equal('releaseDate', null),
              ]),
              contains('label', [plannerType]),
            ]),
          ]
        : []),
    ],
    sort: [['priority', 'asc'].join(',')],
    enabled: plannerTemplateType === PlannerTemplateType.RECOMMENDED && visible,
  });

  useEffect(() => {
    if (account && !isBranchesLoading && !isGroupsLoading) {
      const entities = [];

      if (branches?.length) {
        entities.push({
          label: t('branches'),
          items: formatIncludeExcludeEntities(
            branches,
            'B',
            CourseEntityEnrollEnum.BRANCHES,
          ),
        });
      }

      if (groups?.length) {
        entities.push({
          label: t('groups'),
          items: formatIncludeExcludeEntities(
            groups,
            'G',
            CourseEntityEnrollEnum.GROUPS,
          ),
        });
      }

      groupedEntitiesExclude.current = entities;
      groupedEntitiesInclude.current = [
        ...(isBranchAdmin
          ? []
          : [
              {
                label: t('account'),
                items: formatIncludeExcludeEntities(
                  [account],
                  'A',
                  CourseEntityEnrollEnum.ACCOUNT,
                ),
              },
            ]),
        ...entities,
      ];
    }
  }, [isBranchesLoading, isGroupsLoading]);

  useEffect(() => {
    setIncludedEntities([]);
    setExcludedEntities([]);
    setSelectedCourses([]);

    if (plan?.id) {
      return;
    }

    const handleGeneratePlannerName = async () => {
      const response = await client.planner.getPlanners(
        {
          skip: 0,
          take: 0,
          filters: [equal('type', plannerType)],
        },
        account?.id,
      );

      if (response?.count !== undefined) {
        setPlanName(
          plan?.name ?? plannerType === PlannerType.TRAINING_PLAN
            ? `${t('account.trainingPlan')} ${t('generic.for')} ${
                account?.name
              } #${response?.count + 1}`
            : `${t('planner.type.onboardingPlan')} ${t('generic.for')} ${
                account?.name
              } #${response?.count + 1}`,
        );
      }
    };

    handleGeneratePlannerName();
  }, [visible]);

  useEffect(() => {
    if (plan) {
      setPlanName(plan.name);
      setAutoEnroll(!!plan.autoEnroll);

      const { includedEntities, excludedEntities } =
        processPlanIncludeExcludeEntities(plan, account as Account);

      setIncludedEntities(includedEntities);
      setExcludedEntities(excludedEntities);
      setIncludedEntitiesDescendants(
        extractAllEntitiesDescendants(includedEntities),
      );
      setExcludedEntitiesDescendants(
        extractAllEntitiesDescendants(excludedEntities),
      );

      const planCourses =
        plannerType === PlannerType.ONBOARDING_PLAN
          ? plan.dynamicCourseSchedules
          : plan.staticCourseSchedules;

      setSelectedCourses(
        planCourses.map((item) => ({
          ...item,
          ...item.course,
        })),
      );
    }
  }, [plan]);

  useEffect(() => {
    if (plan) {
      return;
    }
    // Set auto enroll to be enabled for new training plans
    setAutoEnroll(plannerType === PlannerType.TRAINING_PLAN);

    // Set recommended courses or/and upcoming on new plan creation
    if (
      selectedCourses.length ||
      areUpcomingCoursesLoading ||
      areRecommendedCoursesLoading
    )
      return;

    const upcomingToAdd = upcomingCourses
      ?.map((course) => ({
        ...course,
        enrollDate: course?.releaseDate
          ? moment.utc(course.releaseDate).set({ h: 9, m: 0 }).toDate()
          : undefined,
        disabled: true,
      }))
      .filter((course) =>
        recommendedCourses
          ? !recommendedCourses?.find((x) => x.id === course.id)
          : true,
      );

    if (!upcomingCourses?.length && !recommendedCourses?.length) return;

    setSelectedCourses([
      ...(recommendedCourses &&
      plannerTemplateType === PlannerTemplateType.RECOMMENDED
        ? recommendedCourses
        : []),
      ...(upcomingToAdd && plannerType === PlannerType.TRAINING_PLAN
        ? upcomingToAdd
        : []),
    ]);
  }, [
    areUpcomingCoursesLoading,
    areRecommendedCoursesLoading,
    recommendedCourses,
    upcomingCourses,
    plannerTemplateType,
    plannerType,
  ]);

  const extractAllEntitiesDescendants = (items: Branch[]) => {
    return items.flatMap((item: Branch) => {
      const itemMapped = branches?.find(({ id }) => id === item.id);

      return formatIncludeExcludeEntities(
        branches?.filter((b) =>
          itemMapped?.descendants?.some(({ id }: Branch) => id === b.id),
        ) || [],
        'B',
        CourseEntityEnrollEnum.BRANCHES,
      );
    });
  };

  const handleIncludeExcludeChange = (
    event: MultiSelectChangeEvent,
    isInclude: boolean,
  ) => {
    const items = event.value;

    const descendantsFormatted = extractAllEntitiesDescendants(items);
    const allEntities = [...items, ...descendantsFormatted];
    const uniqueEntities = uniqBy(allEntities, 'id');

    if (isInclude) {
      setIncludedEntities(
        items.filter(
          ({ id }: Branch | Group) =>
            !descendantsFormatted.some((des) => des.id === id),
        ),
      );
      setIncludedEntitiesDescendants(descendantsFormatted);
    } else {
      setExcludedEntities(uniqueEntities);
      setExcludedEntitiesDescendants(descendantsFormatted);
    }
  };

  const checkIsIncludeOptionDisabled = (option: Account | Branch | Group) => {
    if (
      includedEntities.find((entity) => entity.id === account?.id) &&
      option.id !== account?.id
    )
      return true;
    return (
      excludedEntities.some((excluded) => excluded.id === option.id) ||
      getAllParentIds(branches?.find(({ id }) => id === option.id)).some(
        (parentId) => includedEntities.map(({ id }) => id).includes(parentId),
      )
    );
  };

  const checkIsExcludeOptionDisabled = (option: any) => {
    return (
      includedEntities.some((included) => included.id === option.id) ||
      getAllParentIds(branches?.find(({ id }) => id === option.id)).some(
        (parentId) => excludedEntities.map(({ id }) => id).includes(parentId),
      ) ||
      option?.descendants?.some((descendant: BranchDescendant) =>
        includedEntities.map(({ id }) => id).includes(descendant.id),
      )
    );
  };

  const updateCourseDate = (date: Date, item: PlannerCRUDCourse) => {
    const enrollDate = moment(date).isSame(moment(), 'day')
      ? moment.utc(new Date())
      : moment.utc(date).set({ h: 9, m: 0 });

    const courses = selectedCourses.map((course: PlannerCRUDCourse) => {
      if (course.id === item.id && course.enrollDate === item.enrollDate) {
        return { ...course, enrollDate: enrollDate.toDate() };
      }
      return course;
    });

    setSelectedCourses(courses as PlannerCRUDCourse[]);
  };

  const updateCoursePeriod = (
    periodValue: number,
    periodUnit: CourseSchedulePeriodUnit,
    itemId: string,
  ) => {
    setSelectedCourses(() => {
      return selectedCourses.map((course: PlannerCRUDCourse) => {
        if (course.id === itemId) {
          return { ...course, periodValue, periodUnit };
        }

        return course;
      });
    });
  };

  const addUpcoming = (courses?: PlannerCRUDCourse[]) => {
    let upcomingToAdd = upcomingCourses?.map((course) => ({
      ...course,
      enrollDate: course?.releaseDate
        ? moment.utc(course.releaseDate).set({ h: 9, m: 0 }).toDate()
        : undefined,
      disabled: true,
    }));

    if (courses?.length) {
      upcomingToAdd = upcomingToAdd?.filter(
        (course) => !courses.find((x) => x.id === course.id),
      );
    }

    if (upcomingToAdd) {
      setSelectedCourses([...(courses ?? []), ...upcomingToAdd]);
    }
  };

  const removeUpcoming = (courses?: PlannerCRUDCourse[]) => {
    if (courses?.length) {
      setSelectedCourses(courses.filter((course) => !course.disabled));
    }
  };

  const { lastMessage, setNotificationParam, notificationParam, isConnected } =
    useNotifications(client.planner.accountCoursePlannersNotifyUrl);

  useEffect(() => {
    if (!notificationParam) return;
    handleNotifySavePlanner(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleNotifySavePlanner = async (messages: any) => {
    if (
      !messages?.data?.event ||
      ![
        'course-planner.add-courses.finished',
        'course-planner.add-courses.error',
      ].includes(messages?.data?.event)
    )
      return;

    if (messages?.data?.event === 'course-planner.add-courses.finished') {
      if (refetch) {
        await refetch();
      }
      toast?.success(t('toast.success'), t('planner.updated'));
    } else if (messages?.data?.event === 'course-planner.add-courses.error')
      toast?.error(t('toast.error'), messages?.data?.error, 5000);
    setNotificationParam(undefined);
    onHide();
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const createPlanner = useCreatePlanner();
  const updatePlanner = useUpdatePlanner();

  const handleSavePlanner = async () => {
    if (!account?.id) return;

    const shouldNotify = autoEnroll && (!plan?.autoEnroll || !plan?.id);

    const payload = {
      accountId: account.id,
      id: plan?.id,
      name: planName,
      targets: {
        branches: getEntityIdsByType(
          includedEntities,
          CourseEntityEnrollEnum.BRANCHES,
        ),
        groups: getEntityIdsByType(
          includedEntities,
          CourseEntityEnrollEnum.GROUPS,
        ),
      },
      courses: selectedCourses
        .filter((x) => !x.disabled)
        .map((course: any) => ({
          id: course.id,
          enrollDate:
            plannerType === PlannerType.TRAINING_PLAN
              ? course.enrollDate || moment().utc(true).toDate()
              : undefined,
          enrollOffset:
            plannerType === PlannerType.ONBOARDING_PLAN
              ? {
                  value: course.periodValue || 0,
                  unit: course.periodUnit || CourseSchedulePeriodUnit.DAYS,
                }
              : undefined,
        })),
      excludes: {
        branches: getEntityIdsByType(
          excludedEntities,
          CourseEntityEnrollEnum.BRANCHES,
        ),
        groups: getEntityIdsByType(
          excludedEntities,
          CourseEntityEnrollEnum.GROUPS,
        ),
        users: getEntityIdsByType(
          excludedEntities,
          CourseEntityEnrollEnum.USERS,
        ),
      },
      type: plannerType || PlannerType.TRAINING_PLAN,
      autoEnroll,
    };

    setIsSubmitting(true);

    if (shouldNotify && account) {
      setNotificationParam(account.id);

      // Wait for a notify connection to establish
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (isConnected.current) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 100);
      });
    }

    try {
      const action = !plan?.id ? createPlanner.create : updatePlanner.update;
      if (isConnected.current || !shouldNotify) {
        await action(payload);

        if (!shouldNotify) {
          if (refetch) {
            await refetch();
          }
          plan?.id
            ? toast?.success(t('toast.success'), t('planner.updated'))
            : toast?.success(t('toast.success'), t('planner.created'));
        }
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    } finally {
      onHide();
      setIsSubmitting(false);
    }
  };

  const [isDeleting, setIsDeleting] = useState(false);
  const deletePlanner = useDeletePlanner();
  const handleDeletePlanner = async () => {
    if (!account?.id || !plan?.id) return;

    setIsDeleting(true);

    try {
      await deletePlanner.delete({
        accountId: account.id,
        planId: plan?.id,
      });

      if (refetch) {
        await refetch();
      }

      toast?.success(t('toast.success'), t('planner.deleted'));
      onHide();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    } finally {
      setIsDeleting(false);
    }
  };

  const onChangeExcludeDropdownMode = async () => {
    setIsDropdownLoading(true);

    if (
      shouldRefetchUsers &&
      !isExcludeUsers &&
      ((isBranchAdmin && includedEntities?.length) || !isBranchAdmin)
    ) {
      const users = await refetchUsers();

      groupedUsers.current = [
        {
          label: t('users'),
          items: formatIncludeExcludeEntities(
            users.data || [],
            'U',
            CourseEntityEnrollEnum.USERS,
          ),
        },
      ];

      setShouldRefetchUsers(false);
    }

    setIsExcludeUsers(!isExcludeUsers);

    setTimeout(() => {
      setIsDropdownLoading(false);
    }, 250);
  };

  useEffect(() => {
    setShouldRefetchUsers(true);
  }, [includedEntities?.length, excludedEntities?.length]);

  const headerTemplate = () => (
    <StyledHeaderContainer justify="space-between" wrap="wrap">
      <h1>
        <EditableText
          key={planName}
          value={planName}
          saveValue={(value: string) => setPlanName(value)}
        />
      </h1>
      {plannerType === PlannerType.TRAINING_PLAN && (
        <StyledHeaderDiv>
          <p>
            {t(
              t('planner.upcomingCourses', {
                platformName: account?.platformSettings?.name,
              }),
            )}
          </p>
          <InputSwitch
            checked={autoEnroll}
            onChange={(e) => {
              setIsAutoEnrollConfirmVisibile(true);
              setAutoEnrollTemp(e.value);
            }}
            disabled={areUpcomingCoursesLoading}
          />
        </StyledHeaderDiv>
      )}
    </StyledHeaderContainer>
  );

  const footerTemplate = (
    <StyledLink onClick={onChangeExcludeDropdownMode}>
      {isExcludeUsers
        ? t('planner.excludeBranchesGroups')
        : t('planner.excludeUsers')}
    </StyledLink>
  );

  const selectedItemTemplate = (
    option: Account | Branch | Group | User,
    isInclude: boolean,
  ) => {
    return option ? (
      <div
        className={
          (isInclude && checkIsIncludeOptionDisabled(option)) ||
          (!isInclude && checkIsExcludeOptionDisabled(option))
            ? 'p-multiselect-token p-disabled'
            : 'p-multiselect-token'
        }
        data-pc-section="token"
      >
        <span
          className="p-multiselect-token-label"
          data-pc-section="tokenlabel"
        >
          {option?.name}
        </span>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="p-icon p-multiselect-token-icon"
          aria-hidden="true"
          onClick={() =>
            isInclude
              ? setIncludedEntities(
                  includedEntities.filter(({ id }) => id !== option.id),
                )
              : setExcludedEntities(
                  excludedEntities.filter(({ id }) => id !== option.id),
                )
          }
        >
          <RemoveIconPath />
        </svg>
      </div>
    ) : null;
  };

  const isDeletionDisabled = () => {
    if (plannerType === PlannerType.TRAINING_PLAN) {
      return (
        !!plan?.staticCourseSchedules?.length &&
        plan?.staticCourseSchedules?.some((course) => !course.executedAt)
      );
    }

    return !!plan?.dynamicCourseSchedules?.length;
  };

  const loadingTemplate = () =>
    Array.from({ length: 5 }).map((_, index) => (
      <div key={index} className="flex align-items-center p-2">
        <Skeleton key={index} width="100%" height="32px" />
      </div>
    ));

  return (
    <StyledDialog
      header={headerTemplate}
      blockScroll
      visible={visible}
      onHide={onHide}
      draggable={false}
      closable={!isSubmitting}
      dismissableMask={!isSubmitting}
      closeOnEscape={!isSubmitting}
      data-testid="create-update-planner-modal"
    >
      <StyledTopDiv>
        <StyledSpan>
          {t('planner.enrollTo')}
          <span className="red"> *</span>
        </StyledSpan>
        <FlexContainer justify="flex-start" gap={12}>
          <StyledMultiSelect
            dropdownIcon={
              isBranchesLoading || isGroupsLoading
                ? 'pi pi-spinner pi-spin'
                : 'pi pi-chevron-down'
            }
            dataKey="id"
            value={includedEntities}
            options={groupedEntitiesInclude.current}
            onChange={(event) => handleIncludeExcludeChange(event, true)}
            filter
            optionLabel="name"
            optionGroupLabel="label"
            optionGroupChildren="items"
            placeholder={
              isBranchAdmin ? t('branch.select') : t('generic.select')
            }
            display="chip"
            className="flex-1"
            disabled={isBranchesLoading || isGroupsLoading}
            optionDisabled={checkIsIncludeOptionDisabled}
            panelClassName="hide-first-letter"
            style={{ width: '200px' }}
            panelStyle={{ width: '200px' }}
            selectedItemTemplate={(item) => selectedItemTemplate(item, true)}
          />
          {t('generic.excluding')}
          <StyledMultiSelect
            dropdownIcon={
              isBranchesLoading || isGroupsLoading || isUsersLoading
                ? 'pi pi-spinner pi-spin'
                : 'pi pi-chevron-down'
            }
            dataKey="id"
            value={excludedEntities}
            options={
              isExcludeUsers
                ? groupedUsers.current
                : groupedEntitiesExclude.current
            }
            onChange={(event) => handleIncludeExcludeChange(event, false)}
            filter
            optionLabel="name"
            optionGroupLabel="label"
            optionGroupChildren="items"
            placeholder={t('generic.none')}
            display="chip"
            className="flex-1"
            disabled={isBranchesLoading || isGroupsLoading || isUsersLoading}
            optionDisabled={checkIsExcludeOptionDisabled}
            panelClassName="hide-first-letter"
            style={{ width: '200px' }}
            panelStyle={{ width: '200px' }}
            virtualScrollerOptions={
              isDropdownLoading
                ? {
                    loadingTemplate,
                    showLoader: true,
                    loading: isDropdownLoading,
                  }
                : undefined
            }
            panelFooterTemplate={footerTemplate}
            selectedItemTemplate={(item) => selectedItemTemplate(item, false)}
            onHide={() => setIsExcludeUsers(false)}
          />
        </FlexContainer>
      </StyledTopDiv>
      <StyledGrayFlexContainer direction="column" gap={12} width="100%">
        <PlannerCRUDTimeline
          items={selectedCourses}
          isOnboardingPlan={plannerType === PlannerType.ONBOARDING_PLAN}
          onItemAdd={(item: PlannerCRUDCourse) => {
            if (
              selectedCourses.find(
                (course) =>
                  course.id === item.id &&
                  course.enrollDate === item.enrollDate,
              )
            ) {
              item.enrollDate = moment(item.enrollDate).add(1, 'day').toDate();
            }

            setSelectedCourses([...selectedCourses, item]);
          }}
          onItemDateChange={updateCourseDate}
          onItemPeriodChange={updateCoursePeriod}
          onItemRemove={(item: PlannerCRUDCourse) =>
            setSelectedCourses(
              selectedCourses.filter(
                (course: PlannerCRUDCourse) =>
                  !(
                    course.id === item.id &&
                    course.enrollDate === item.enrollDate
                  ),
              ),
            )
          }
        />
      </StyledGrayFlexContainer>
      <StyledBottomDiv>
        <FlexContainer justify="space-between">
          {can(Actions.DELETE, Subjects.COURSE_PLANNER) && plan?.id && (
            <FlexContainer justify="flex-start" align="center" gap={12}>
              <AppButton
                type="outlined"
                icon="pi pi-trash"
                isDisabled={isDeletionDisabled()}
                state={
                  isDeleting ? LoadingStatuses.LOADING : LoadingStatuses.IDLE
                }
                onClick={() => {
                  setDialogData({
                    show: true,
                    type: 'confirmation',
                    header: t('dialog.delete'),
                    message: t('planner.delete.confirm'),
                    onAccept: handleDeletePlanner,
                  });
                }}
              />
              {isDeletionDisabled() ? (
                <StyledDeleteInfoSpan>
                  {t('planner.delete.info')}
                </StyledDeleteInfoSpan>
              ) : null}
            </FlexContainer>
          )}
          <FlexContainer justify="flex-end" align="center" gap={12}>
            <AppButton
              label={t('button.close')}
              severity="secondary"
              type="outlined"
              onClick={onHide}
              isDisabled={isSubmitting}
              className="mr-3"
              data-testid="create-update-planner-cancel"
            />
            <AppButton
              label={t('button.save')}
              isDisabled={
                isSubmitting ||
                !includedEntities?.length ||
                (plannerType === PlannerType.TRAINING_PLAN &&
                  !!selectedCourses.find((x) => !x.enrollDate))
              }
              state={
                isSubmitting ? LoadingStatuses.LOADING : LoadingStatuses.IDLE
              }
              data-testid="save-create-update-planner-cancel"
              onClick={handleSavePlanner}
            />
          </FlexContainer>
        </FlexContainer>
      </StyledBottomDiv>
      <Dialog
        blockScroll
        visible={isAutoEnrollConfirmVisibile}
        header={
          autoEnrollTemp
            ? t('planner.toggle.autoEnrollOn.warn.heading', {
                platformName: account?.platformSettings?.name,
              })
            : t('planner.toggle.autoEnrollOff.warn.heading', {
                platformName: account?.platformSettings?.name,
              })
        }
        draggable={false}
        data-testid="auto-enroll-confirm-modal"
        onHide={() => setIsAutoEnrollConfirmVisibile(false)}
        style={{ width: '600px' }}
      >
        {autoEnrollTemp ? (
          <>
            <p>
              {t('planner.toggle.autoEnrollOn.warn.text.1', {
                platformName: account?.platformSettings?.name,
              })}
            </p>
            <p>
              {t('planner.toggle.autoEnrollOn.warn.text.2', {
                platformName: account?.platformSettings?.name,
              })}
            </p>
          </>
        ) : (
          <>
            <p>
              {t('planner.toggle.autoEnrollOff.warn.text.1', {
                platformName: account?.platformSettings?.name,
              })}
            </p>
            <p>
              {t('planner.toggle.autoEnrollOff.warn.text.2', {
                platformName: account?.platformSettings?.name,
              })}
            </p>
          </>
        )}
        <FlexContainer justify="flex-end" className="mt-5">
          <>
            <AppButton
              label={t('button.cancel')}
              type="outlined"
              className="mr-3"
              onClick={() => {
                setIsAutoEnrollConfirmVisibile(false);
              }}
            />
            <AppButton
              label={
                autoEnrollTemp ? t('generic.enable') : t('generic.disable')
              }
              onClick={() => {
                setAutoEnroll(autoEnrollTemp);
                setIsAutoEnrollConfirmVisibile(false);
                autoEnrollTemp
                  ? addUpcoming(selectedCourses)
                  : removeUpcoming(selectedCourses);
              }}
            />
          </>
        </FlexContainer>
      </Dialog>
    </StyledDialog>
  );
};
