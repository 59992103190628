import { handleAxiosError, nested, notEqual } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { MaterialType } from '@/client/courses';
import { Group } from '@/client/groups';
import { Role } from '@/client/roles';
import {
  Actions,
  Subjects,
  SystemRoles,
  User,
  UserFormState,
} from '@/client/users';
import { UserOrigin } from '@/client/users/types/origins.enum';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { UserForm } from '@/components/users/forms';
import {
  useGroupsPartialRequest,
  useMe,
  useRoles,
  useUpdateUser,
  useUser,
  useUserCourses,
} from '@/hooks/query';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { LoadingPage } from '@/pages';
import { selectCurrentAccount } from '@/store/features/account';
import { setCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { cannotManageMultiAdmins, queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { MenuItem } from 'primereact/menuitem';
import { Message } from 'primereact/message';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserTabs } from './UserTabs';

const getBreadcrumbs = (
  account: Account,
  user: User,
  t: TranslationFunctionType,
): MenuItem[] => {
  const items = [
    {
      label: account?.name,
      url: account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id)
        : RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('users'),
      url: RedirectPaths[RedirectPathsEnum.USERS](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: user?.name,
      url: RedirectPaths[RedirectPathsEnum.USERS_EDIT](user?.id),
      template: AppBreadCrumbTemplate,
    },
  ];

  return items;
};

export const UpdateUserPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { cannot } = usePermission();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const { user: currentUser } = useMe({});
  const { roles } = useRoles({
    take: 20,
  });

  const { groups, isLoading: isGroupsLoading } = useGroupsPartialRequest({
    accountId: account?.id,
    sort: ['name,asc'],
  });

  const { user, isLoading } = useUser({
    userId: id,
    accountId: account?.id,
  });
  const updateUser = useUpdateUser();

  const [initialValues, setInitialValues] = useState<UserFormState>({
    firstName: '',
    lastName: '',
    name: '',
    username: '',
    email: '',
    password: undefined,
    role: undefined,
    active: false,
    eFrontSync: false,
    branch: undefined,
    language: '',
    isManual: false,
    assignedAccounts: undefined,
  });

  useEffect(() => {
    if (!user) return;

    setInitialValues({
      firstName: user.firstName,
      lastName: user.lastName,
      name: user.name,
      username: user.username,
      email: user.email,
      active: user.active,
      role: user.role,
      language: user.language,
      branch: user.branch ?? undefined,
      groups: user.groups ?? undefined,
      eFrontSync: true,
      isManual: user.origin === UserOrigin.MANUAL,
      assignedAccounts: user.assignedAccounts,
    });
  }, [user]);

  const handleUserAction = async (data: FormikValues, id?: string) => {
    const result = await updateUser.update({
      userId: id as string,
      accountId: (account as Account)?.id,
      updates: {
        ...data,
        role: data.role.id,
        branch: data.branch ? data.branch.id : null,
        groups: data.groups ? data.groups.map(({ id }: Group) => id) : null,
        assignedAccounts: data.assignedAccounts
          ? data.assignedAccounts.map(({ id }: Account) => id)
          : null,
      },
    });

    if (location.state) {
      navigate(`${location.state.pathname}${location.state.search}`);
    } else {
      navigate(RedirectPaths[RedirectPathsEnum.USERS]());
    }

    return result;
  };

  const handleSubmit = async (data: FormikValues) => {
    try {
      const newUser = await handleUserAction(data, user ? user.id : undefined);

      if (currentUser?.id === newUser.id) {
        dispatch(setCurrentUser({ ...currentUser, ...newUser }));
      }

      toast?.success(
        t('toast.success'),
        user ? t('user.updated') : t('user.created'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const [hasDocuments, setHasDocuments] = useState(false);

  const { isLoading: userDocumentsLoading, userCourses: userDocuments } =
    useUserCourses({
      take: 1,
      accountId: account?.id,
      userId: id,
      filters: [nested('course', [notEqual('type', MaterialType.COURSE)])],
    });

  useEffect(() => {
    setHasDocuments(!!userDocuments?.result?.length);
  }, [userDocuments]);

  if (!user || isLoading || userDocumentsLoading || isGroupsLoading) {
    return <LoadingPage message={t('user.loading')} />;
  }

  const cannotUpdateMultiAdmins = cannotManageMultiAdmins(user, currentUser);

  const isReadOnlyView =
    cannot(Actions.UPDATE, Subjects.USERS) ||
    (account?.isSystem && currentUser?.role.code !== SystemRoles.DEVELOPER) ||
    cannotUpdateMultiAdmins;

  return (
    <>
      <AppBreadCrumb model={getBreadcrumbs(account as Account, user, t)} />
      <h1>{initialValues.name ? initialValues.name : initialValues.email}</h1>
      <UserTabs user={user} hasDocuments={hasDocuments} />
      {cannotUpdateMultiAdmins && (
        <Message
          className="mb-4"
          text={t('generic.contactSupport', {
            platformName: account?.platformSettings?.name,
          })}
        />
      )}
      <FlexContainer justify="flex-start">
        <UserForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          state={queryStateConverter(updateUser)}
          isCreate={false}
          roles={roles?.result as Role[]}
          groups={groups as Group[]}
          disabled={isReadOnlyView}
          isReadOnlyView={isReadOnlyView}
          isSSOEnabled={!!user?.account?.meta?.isSSO}
        />
      </FlexContainer>
    </>
  );
};
