import { FiltersType, ListingRequest, ListingResponse } from '@/api/types';
import { Account } from '@/client/accounts';
import { CampaignUserResultStatusesEnum } from '@/client/campaigns';
import {
  Course,
  CourseAttempt,
  CourseStatusAttemptEnum,
  MaterialType,
} from '@/client/courses';
import { Role } from '@/client/roles';
import { User } from '@/client/users';

export type CourseCompletionReportsType = {
  availableCourses: number;
  enrolledCourses: number;
  totalEnrolments: number;
  totalCompletions: number;
};

export type userAwarenessReportsType = {
  usersWithCompletedCourses: number;
  usersWithEnrolledCourses: number;
  usersWithoutCompletedCourses: number;
  usersWithoutCourses: number;
};

export type OverviewReportsType = {
  activeUsers: number;
  pastPhishingCampaigns: number;
  activePhishingCampaigns: number;
  nextPhishingCampaignDate: Date;
};

export type UsersSummaryReport = {
  account: Account;
  dataSubmitted: number;
  emailsOpened: number;
  emailsSent: number;
  enrolledCourses: number;
  enrolledMaterials: number;
  lastAction: CampaignUserResultStatusesEnum;
  lastGeneratedAt: Date;
  linksClicked: number;
  notCompletedCourses: number;
  notCompletedMaterials: number;
  overdueCourses: number | null;
  phishingCampaigns: number;
  user: User;
  averageScore: number | null;
};

export type UserReportsMeta = {
  companyName: string;
  department: string;
  manager: string;
  country: string;
  jobTitle: string;
  mobilePhone: string;
  officeLocation: string;
};

export type UserAwarenessBranch = {
  id: string;
  name: string;
  parent: { id: string; name: string };
};

export type UserAwarenessCourse = {
  id: string;
  name: string;
  score: number | null;
  status: CourseStatusAttemptEnum;
  completed: Date;
  enrollmentDate: Date;
  dueDate: Date | null;
  type: MaterialType;
};

export type UserAwareness = {
  id: string;
  enrollmentDate: Date;
  dueDate: Date | null;
  user: User;
  course: Pick<Course, 'id' | 'type' | 'name'>;
  bestAttempt: Pick<CourseAttempt, 'id' | 'score' | 'completed'>;
  status: string;
};

export type AttemptsReport = {
  status: string;
  language: string;
  count: number;
};

export type ListUsersSummaryReportRequest = ListingRequest;
export type ListUsersSummaryReportResponse =
  ListingResponse<UsersSummaryReport>;

export type ListUsersAwarenessReportRequest = ListingRequest;
export type ListUsersAwarenessReportResponse = ListingResponse<UserAwareness>;

export type ExportUsersReportRequest = {
  filters: FiltersType | undefined;
  exportKey: string;
  sort: string[];
};

export type ExportUsersReportFormValues = {
  isAllData: boolean;
};

export enum UsersReportType {
  AWARENESS = 'awareness',
  SUMMARY = 'summary',
  PHISHING = 'phishing',
}
