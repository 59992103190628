import classNames from 'classnames';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

export type FormikRichTextAreaProps = {
  field: {
    name: string;
    value: string;
    onChange: () => void;
    onBlur: (e: {
      target: {
        name: string;
      };
    }) => void;
  };
  form: {
    touched: Record<string, any>;
    errors: Record<string, any>;
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void;
  };
  label?: string;
  required?: boolean;
  readOnly?: boolean;
  className?: string;
  usePhysicalStyleTags?: boolean;
};

const StyledReactQuill = styled(ReactQuill)`
  overflow-y: initial;
`;

export const FormikRichTextArea: React.FC<FormikRichTextAreaProps> = ({
  field,
  form,
  label,
  required,
  readOnly,
  className,
  usePhysicalStyleTags,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);

  const fieldError = get(form.errors, field.name);
  const fieldTouched = get(form.touched, field.name);

  const modules = {
    toolbar: [
      [{ size: [] }],
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
  };

  useEffect(() => {
    const quill = ReactQuill.Quill;
    const bold = quill.import('formats/bold');
    const italic = quill.import('formats/italic');

    if (usePhysicalStyleTags) {
      bold.tagName = 'b';
      italic.tagName = 'i';
    } else {
      bold.tagName = ['strong', 'b'];
      italic.tagName = ['em', 'i'];
    }

    quill.register(bold, true);
    quill.register(italic, true);
  }, []);

  return (
    <>
      <label
        htmlFor={field.name}
        className={classNames({
          'p-error': fieldTouched && !!fieldError,
        })}
      >
        {label}
        {required ? <span className="red"> *</span> : ''}
      </label>
      <StyledReactQuill
        modules={modules}
        {...field}
        value={field.value || ''}
        className={classNames(
          {
            'p-editor-error': fieldTouched && !!fieldError,
            'p-editor-focused': focus,
            'p-editor-disabled': readOnly,
          },
          className,
          'ql-editor',
        )}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          field.onBlur({
            target: {
              name: field.name,
            },
          });
          setFocus(false);
        }}
        onChange={(newValue, delta, source, editor) => {
          if (source === 'user') {
            const textContent = editor.getText().trim();
            if (textContent.length > 0) {
              form.setFieldValue(field.name, newValue);
            } else {
              form.setFieldValue(field.name, '');
            }
          }
        }}
        {...rest}
      />
      {fieldTouched && !!fieldError ? (
        <small className="p-error">{fieldError}</small>
      ) : null}
    </>
  );
};
