import { SenderProfileType } from '@/client/campaigns';
import {
  BranchStatusEnum,
  CourseStatusAttemptEnum,
  UserStatusEnum,
} from '@/client/courses';
import { TranslationFunctionType } from '@/common/types';
import { AppChip } from '@/ui/chip';
import { uniqueId } from 'lodash';
import { Tooltip } from 'primereact/tooltip';
import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';

export const determineAttemptStatusColor = (
  status: string,
  t: TranslationFunctionType,
) => {
  switch (status) {
    case CourseStatusAttemptEnum.IN_PROGRESS:
      return (
        <AppChip
          label={t('course.attempt.status.inProgress')}
          type="secondary"
        />
      );
    case CourseStatusAttemptEnum.COMPLETED:
      return (
        <AppChip label={t('course.attempt.status.completed')} type="primary" />
      );
    case CourseStatusAttemptEnum.FAILED:
      return <AppChip label={t('course.attempt.status.failed')} type="error" />;
    default:
      return (
        <AppChip label={t('course.attempt.status.enrolled')} type="secondary" />
      );
  }
};

export const determineScoreColor = (status: string, score: number) => {
  switch (status) {
    case CourseStatusAttemptEnum.IN_PROGRESS:
      return <AppChip label={score.toString()} type="secondary" />;
    case CourseStatusAttemptEnum.COMPLETED:
      return <AppChip label={score.toString()} type="primary" />;
    case CourseStatusAttemptEnum.FAILED:
      return <AppChip label={score.toString()} type="error" />;
    default:
      return <AppChip label={score.toString()} type="secondary" />;
  }
};

export const determineUserStatusColor = (
  status: string,
  t: TranslationFunctionType,
) => {
  switch (status) {
    case UserStatusEnum.IN_PROGRESS:
      return (
        <AppChip
          label={t('course.attempt.status.inProgress')}
          type="secondary"
        />
      );
    case UserStatusEnum.COMPLETED:
      return (
        <AppChip label={t('course.attempt.status.completed')} type="primary" />
      );
    case UserStatusEnum.ENROLLED:
      return (
        <AppChip label={t('course.attempt.status.enrolled')} type="secondary" />
      );
    case UserStatusEnum.NOT_SCHEDULED:
      return (
        <AppChip label={t('course.attempt.status.notScheduled')} type="error" />
      );
    case UserStatusEnum.SCHEDULED:
      return (
        <AppChip
          label={t('course.attempt.status.scheduled')}
          type="secondary"
        />
      );
    default:
      return null;
  }
};

export const determineBranchStatusColor = (
  status: string,
  t: TranslationFunctionType,
) => {
  switch (status) {
    case BranchStatusEnum.NOT_SCHEDULED:
      return (
        <AppChip label={t('course.attempt.status.notScheduled')} type="error" />
      );
    case BranchStatusEnum.SCHEDULED:
      return (
        <AppChip
          label={t('course.attempt.status.scheduled')}
          type="secondary"
        />
      );
    case BranchStatusEnum.ENROLLED:
      return (
        <AppChip label={t('course.attempt.status.enrolled')} type="secondary" />
      );
    default:
      return null;
  }
};

export const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const difficultyOptions = [
  { label: <>&#x1f41f;</>, value: 1 },
  { label: <>&#x1f41f;&#x1f41f;</>, value: 2 },
  { label: <>&#x1f41f;&#x1f41f;&#x1f41f;</>, value: 3 },
  { label: <>&#x1f41f;&#x1f41f;&#x1f41f;&#x1f41f;</>, value: 4 },
  { label: <>&#x1f41f;&#x1f41f;&#x1f41f;&#x1f41f;&#x1f41f;</>, value: 5 },
];

export const difficultyFilterOptions = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
];

export const displayDifficulty = (campaignDifficulty: number): ReactNode => {
  const option = difficultyOptions.find((x) => x.value === campaignDifficulty);
  return option?.label;
};

export const senderOptions = (t: TranslationFunctionType) => [
  { label: t('generic.internal'), value: SenderProfileType.INTERNAL },
  { label: t('generic.external'), value: SenderProfileType.EXTERNAL },
];

const StyledUsers = styled.span`
  font-style: italic;
  color: var(--gray-darker);
  cursor: pointer;
`;

export const displayMiltipleUsers = (
  nameString: string,
  t: TranslationFunctionType,
) => {
  const nameArr = nameString.split(',');
  const showUserNames = nameArr.slice(0, 3);
  const showMoreUsers = nameArr.slice(3);
  const id = uniqueId('users');

  return nameArr.length <= 3 ? (
    nameArr.join(', ')
  ) : (
    <>
      <span>{showUserNames.join(', ')}</span>
      <StyledUsers id={id}>
        {` ${t('users.more.count', {
          count: showMoreUsers.length,
        })}`}
      </StyledUsers>
      <Tooltip
        position="mouse"
        target={`#${id}`}
        content={showMoreUsers.join(', ')}
      />
    </>
  );
};
