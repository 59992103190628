import { nested, notEqual } from '@/api/helpers';
import { MaterialType } from '@/client/courses';
import { User, UserCourses } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import {
  DataTable,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableToolbar,
} from '@/components/tables/crud';
import { useUserCourses } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { selectCurrentAccount } from '@/store/features/account';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { determineUserStatusColor } from '@/utils/helpers/ui.helper';
import { t } from 'i18next';
import { DataTableRowClickEvent } from 'primereact/datatable';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type UserDocumentsDatatableProps = {
  user: User;
};

export const UserDocumentsDatatable: React.FC<UserDocumentsDatatableProps> = ({
  user,
}) => {
  const account = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();

  const { skip, take, sort, apiFilters, onSort, onPage } = useTable();

  const { isLoading, userCourses: userDocuments } = useUserCourses({
    accountId: account?.id,
    userId: user?.id,
    take,
    skip,
    filters: [
      nested('course', [notEqual('type', MaterialType.COURSE)]),
      ...apiFilters,
    ],
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });

  const columns: DataTableColumnType[] = [
    {
      field: 'course',
      header: t('materials.enrolled'),
      sortable: false,
      filterable: false,
      render: (row: UserCourses) => row?.course.name,
    },
    {
      field: 'date',
      header: t('generic.enrollDate'),
      sortable: false,
      filterable: false,
      render: (row: UserCourses) =>
        row?.enrollDate ? (
          <FormatDate date={row?.enrollDate} />
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'completeDate',
      header: t('reports.courses.completionDate'),
      sortable: false,
      filterable: false,
      render: (row: UserCourses) =>
        row?.attempt?.completed ? (
          <FormatDate date={row?.attempt?.completed} />
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'status',
      header: t('generic.status'),
      sortable: false,
      filterable: false,
      // filters: {
      //   field: FilterNamesEnum.AWARENESS_REPORTS_STATUS,
      //   type: FilterTypeEnum.MULTI_SELECT,
      //   options: getAwarenessReportStatusOptions(t),
      //   placeholder: t('generic.status.search'),
      // },
      render: (row: UserCourses) => determineUserStatusColor(row?.status, t),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['course'];
  //

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        {/* <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          className="flex-initial"
        /> */}
        <></>
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.USER_MATERIALS_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const onRowClick = (e: DataTableRowClickEvent) =>
    navigate(
      RedirectPaths[
        e.data?.course?.type === MaterialType.RESOURCE
          ? RedirectPathsEnum.RESOURCES_EDIT
          : RedirectPathsEnum.CUSTOM_MATERIALS_EDIT
      ](e.data?.course?.id),
    );

  return (
    <DataTable
      dataKey="dataId"
      data={userDocuments?.result.map((x) => ({
        ...x,
        dataId: `${x.id}-${x.course?.id}`,
      }))}
      count={userDocuments?.count as number}
      isLoading={isLoading}
      toolbar={toolbar}
      columns={columns}
      visibleColumns={visibleColumns}
      onPage={onPage}
      rows={take}
      skip={skip}
      onSort={onSort}
      sort={sort}
      onRowClick={onRowClick}
    />
  );
};
